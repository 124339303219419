import { EventSourcePolyfill } from "event-source-polyfill";
import { isEqual } from "lodash";
import client from "../client";
import { mercureEndpoint } from "../../config";

const REFRESH_CHECK = 120;

const contextSSE = {
  eventSource: null,
  lastEventAt: null,
  refreshCheckInterval: null,
};

const subscribeSSE = ({ dispatch }, restaurateurSlug, slug) => {
  unsubscribeSSE();
  // eslint-disable-next-line no-console
  console.log("offers subscribeSSE");

  const url = new URL(mercureEndpoint);
  url.searchParams.append(
    "topic",
    `https://example.com/fridges/${slug}/offers/{id}`
  );
  url.searchParams.append(
    "topic",
    `https://example.com/restaurateurs/${restaurateurSlug}/products/{id}`
  );

  const eventSource = new EventSourcePolyfill(url.toString(), {
    lastEventIdQueryParameterName: "Last-Event-Id",
    heartbeatTimeout: 3600 * 1000,
  });

  eventSource.onmessage = (event) => {
    const sseData = JSON.parse(event.data);

    // eslint-disable-next-line no-console
    console.debug("offers mercure subscription event", sseData);

    if (
      sseData &&
      sseData["@type"] &&
      (sseData["@type"] === "Product" || sseData["@type"] === "Offer")
    ) {
      unsubscribeSSE();
      contextSSE.lastEventAt = new Date();
      setTimeout(() => dispatch.offers.refresh(), 1000);
    }
  };

  eventSource.onerror = (err) => {
    // eslint-disable-next-line no-console
    console.log("offers mercure subscription error", err);
  };

  contextSSE.eventSource = eventSource;

  contextSSE.refreshCheckInterval = setInterval(() => {
    const now = new Date();
    if (
      !contextSSE.lastEventAt ||
      now.getTime() - contextSSE.lastEventAt.getTime() > REFRESH_CHECK * 1000
    ) {
      dispatch.offers.refresh();
    }
  }, 1000 * REFRESH_CHECK);
};

const unsubscribeSSE = () => {
  // eslint-disable-next-line no-console
  console.log("offers unsubscribeSSE");

  if (contextSSE.refreshCheckInterval) {
    clearInterval(contextSSE.refreshCheckInterval);
  }
  if (contextSSE.eventSource) {
    contextSSE.eventSource.close();
  }
};

export default {
  state: {
    slug: null,
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSlug: (state, slug) => ({ ...state, slug }),
    setData: (state, data) => {
      if (isEqual(state.data, data)) return state;
      // eslint-disable-next-line no-console
      console.log("new offers data", state.data, data);
      return { ...state, data };
    },
    setLoading: (state, loading) => ({ ...state, loading }),
    setError: (state, error) => {
      if (state.error === error) return state;
      return { ...state, error };
    },
  },
  effects: (dispatch) => ({
    async load({ refresh = false } = {}, rootState) {
      if (!rootState.fridge.data) {
        return;
      }
      if (!refresh) {
        this.setLoading(true);
      }
      this.setError(null);

      // Configuration de la logique de retry
      const MAX_RETRIES = 10; // Nombre maximal de tentatives
      const BASE_DELAY = 2000; // Temps de base pour le délai (en millisecondes)

      const fetchWithRetry = async (attempt = 1) => {
        try {
          // Appel de l'API
          const offers = await client.fridgeOffers(rootState.fridge.data.slug);
          this.setSlug(rootState.fridge.data.slug);
          this.setData(offers);

          // Abonnement SSE sur succès
          subscribeSSE(
            { dispatch },
            rootState.fridge.data.restaurateur.slug,
            rootState.fridge.data.slug
          );
        } catch (err) {
          if (attempt < MAX_RETRIES) {
            // Calculer le délai exponentiel
            const retryDelay = BASE_DELAY * Math.pow(2, attempt - 1);
            console.warn(
              `Tentative ${attempt} échouée. Nouvel essai dans ${retryDelay}ms...`
            );

            // Attendre avant de réessayer
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            return fetchWithRetry(attempt + 1); // Retenter la requête
          } else {
            // Journaliser l'échec final après le nombre maximal de tentatives
            console.error("Échec après plusieurs tentatives (Load offers)", err);
            this.setError(err); // Mettre l'erreur dans Redux
          }
        }
      };

      try {
        await fetchWithRetry(); // Lancer la requête avec la logique de retry
      } finally {
        this.setLoading(false); // Indicateur de chargement désactivé
      }
    },
    async refresh(_, rootState) {
      if (!rootState.offers.data) return;

      dispatch.offers.load({
        refresh: true,
      });
    },
  }),
};
